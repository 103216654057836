import Head from 'next/head';
import React from 'react';
import type { NextHeadWithMetaProps } from './NextHeadWithMeta.types';

export const NextHeadWithMeta: React.FC<NextHeadWithMetaProps> = ({
  title,
  meta,
}) => {
  return (
    <Head>
      {title && <title>{title}</title>}
      {meta && meta.map((tag) => <meta key={tag.name} {...tag} />)}
    </Head>
  );
};
