import type { GetStaticProps } from 'next';

import { fetchHomePageStrapiData } from '../utils/api/fetchStrapiData';
import Homepage from '../views/homePageDesign23';

export const getStaticProps: GetStaticProps = async () => {
  const data = await fetchHomePageStrapiData();
  return {
    props: data,
  };
};

export default Homepage;
