import { Stack, styled, Typography } from '@mui/material';
import { Section } from '@rumblefish/ui/Rumblefish23Theme';
export const TestimonialsSection = styled(Section)(() => ({
  contain: 'paint',
}));
export const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0, 0, 20, 0),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    gap: theme.spacing(8),
    padding: theme.spacing(0, 0, 10, 0),
  },
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(6),
    padding: theme.spacing(0, 0, 9, 0),
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(4),
  },
}));
export const StyledTextStack = styled(Stack)(({ theme }) => ({
  // textAlign: 'center',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
}));
export const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  width: '48%',
  [theme.breakpoints.down('xl')]: {
    width: '60%',
  },
  [theme.breakpoints.down('md')]: {
    width: '65%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const StyledButtonsStack = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  [theme.breakpoints.down(800)]: {
    position: 'static',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: theme.spacing(6),
  },
}));
export const NameBannerStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down(800)]: {
    margin: theme.spacing(0, 0, 3, 0),
  },
}));
export const StyledQuote = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontSize: '128px !important',
  lineHeight: '112px !important',
  height: '58px',
  [theme.breakpoints.up(800)]: {
    display: 'none',
  },
}));
