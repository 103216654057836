export const seoKeywords = {
  name: 'keywords',
  content:
    'software development company, hire software developers, custom software development, software engineers to hire, blockchain development house, software house to hire, end-to-end software development, software development services',
};

//TODO add images for socialmedia using Open Graph
export const homeSeo = {
  title:
    'Top Rated End-to-End Custom Software Development Partner | Rumble Fish Software Development',
  meta: [
    seoKeywords,
    {
      name: 'description',
      content:
        'Rumble Fish is a Software Development Company specializing in Blockchain, AI, FinTech, and AWS Cloud Applications. Build your custom digital product with a team of experienced software engineers. We offer end-to-end development services and IT consulting for your project.',
    },
  ],
};
