import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@mui/material';
import { Section } from '@rumblefish/ui/Rumblefish23Theme';
import { useAnimation } from 'framer-motion';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import type { IArrowIcon, IBar } from './JoinThem.types';
import {
  BarMaskDescription,
  BarMaskTitle,
  BarTitle,
  Description,
  StyledArrowIcon,
  StyledBarTitleStack,
  StyledBarsStack,
  StyledMaskContainer,
  StyledSingleBarStack,
  StyledSingleInnerBarStack,
  StyledStack,
  TextStack,
  Title,
} from './styles';

const ArrowSvgIcon = ({
  controls,
  isHoverIcon,
}: IArrowIcon): ReactJSXElement => {
  return (
    <StyledArrowIcon
      className={isHoverIcon ? 'ArrowIcon-hover' : 'ArrowIcon-static'}
      animate={controls}
      viewBox="0 0 64 64"
      fill="none"
      initial="initial"
      xmlns="http://www.w3.org/2000/svg"
      transition={{ duration: 0.15 }}
      variants={{
        hover: {
          visibility: !isHoverIcon ? 'hidden' : 'visible',
          transform: 'rotate(0deg)',
        },
        initial: {
          visibility: 'visible',
          transform: 'rotate(-45deg)',
        },
      }}>
      <path
        d="M51.9733 32.0269L12 32.0269M35.9627 16.0615L52.0053 32.0002L35.9627 47.9389"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </StyledArrowIcon>
  );
};
const Bar = ({ title, desc, link }: IBar): ReactJSXElement => {
  const controls = useAnimation();
  const theme = useTheme();
  const variants = {
    hover: {
      height: '100%',
    },
    initial: {
      height: '0px',
    },
  };
  const changeControls = (state: 'hover' | 'initial') => {
    const isMobile = theme.utils.isMobile();
    if (isMobile) return;
    controls.start(state);
  };
  const router = useRouter();
  const handleClick = useCallback(() => {
    if (!link) return;
    router.push(link);
  }, [link, router]);
  return (
    <StyledSingleBarStack onClick={handleClick}>
      <StyledMaskContainer
        onHoverStart={() => changeControls('hover')}
        onHoverEnd={() => changeControls('initial')}
        initial="initial"
        transition={{ duration: 0.15 }}
        variants={variants}
        animate={controls}>
        <StyledSingleInnerBarStack>
          <StyledBarTitleStack>
            <ArrowSvgIcon controls={controls} isHoverIcon={true} />
            <BarMaskTitle variant="display_sm">
              {title.toUpperCase()}
            </BarMaskTitle>
          </StyledBarTitleStack>

          <BarMaskDescription variant="strong_600">{desc}</BarMaskDescription>
        </StyledSingleInnerBarStack>
      </StyledMaskContainer>
      <StyledSingleInnerBarStack>
        <StyledBarTitleStack
          onHoverStart={() => changeControls('hover')}
          onHoverEnd={() => changeControls('initial')}>
          <ArrowSvgIcon controls={controls} isHoverIcon={false} />
          <BarTitle variant="display_sm">{title.toUpperCase()}</BarTitle>
        </StyledBarTitleStack>
      </StyledSingleInnerBarStack>
    </StyledSingleBarStack>
  );
};
export const JoinThem = (): ReactJSXElement => {
  const jointThemData: IBar[] = [
    {
      title: 'TURNTABLE',
      desc: 'A hybrid of a social network and a music app',
      link: '/case-studies/tt-fm/',
    },
    {
      title: 'MAKERDAO',
      desc: 'The first truly decentralized stablecoin crypto on Ethereum',
      link: '/case-studies/makerdao/',
    },
    {
      title: 'ZBAY',
      desc: 'A private inbox, wallet, and marketplace all in one',
      link: '/case-studies/zbay-a-private-inbox-wallet-and-marketplace-all-in-one/',
    },
    {
      title: 'VERIFYID',
      desc: 'An identity verification MVP',
      link: '/case-studies/verifiid/',
    },
  ];
  return (
    <Section width="normal">
      <StyledStack>
        <TextStack>
          <Description variant="strong_600">
            We're trusted by global innovators and leaders.
          </Description>
          <Title variant="display_lg">Join them!</Title>
        </TextStack>
        <StyledBarsStack>
          {jointThemData.map((bar, index) => {
            return (
              <Bar
                key={index}
                title={bar.title}
                desc={bar.desc}
                link={bar.link}
              />
            );
          })}
        </StyledBarsStack>
      </StyledStack>
    </Section>
  );
};
