import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { ThemeProvider } from '@mui/material';
import {
  Section,
  NameBanner,
  BlogPostSnippet,
} from '@rumblefish/ui/Rumblefish23Theme';
import { themeLight } from '@rumblefish/ui/src/theme/rumblefish23Theme';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import polishBlockchainImage from '../../../public/png/logos/PBALogo.png';
import swissPolishBlockchainImage from '../../../public/png/logos/SPBALogo.png';
import type { HomePageRumbleFishBlogProps } from './HomePageRumbleFishBlog.types';
import {
  TextStack,
  BlogsStack,
  StyledLink,
  StyledImage,
  StyledStack,
  StyledTitle,
  ContentStack,
  MobileButton,
  DesktopButton,
  StyledImageWrapper,
  StyledHeadlineWrapper,
} from './styles';

export const HomePageRumbleFishBlog = ({
  blogPosts,
}: HomePageRumbleFishBlogProps): ReactJSXElement => {
  const postsToDisplay = blogPosts.slice(0, 2);
  const router = useRouter();
  const handleOnClick = useCallback(
    (linkTo: string) => {
      if (!linkTo) return;
      router.push(linkTo);
    },
    [router]
  );

  return (
    <>
      <Section width="normal">
        <StyledStack>
          <NameBanner type="section" text={'Rumblefish Blog'} />

          <ContentStack>
            <TextStack>
              <StyledTitle variant="display_sm">
                Check a piece of expert knowledge
              </StyledTitle>

              <DesktopButton link="/blog" variant="primary">
                More updates
              </DesktopButton>
            </TextStack>

            <BlogsStack>
              {postsToDisplay.map(({ attributes }, index) => (
                <BlogPostSnippet
                  onClick={() =>
                    handleOnClick(`/blog/post/${attributes?.slug || ''}`)
                  }
                  key={index}
                  mainImageSrc={attributes?.image.data?.attributes?.url || ''}
                  authors={attributes?.authors?.data || []}
                  title={attributes?.title || ''}
                  textContent={attributes?.content || ''}
                  technologies={attributes?.category || []}
                />
              ))}
            </BlogsStack>

            <MobileButton link="/blog" variant="primary">
              More updates
            </MobileButton>
          </ContentStack>
        </StyledStack>
      </Section>

      <ThemeProvider theme={themeLight}>
        <Section forceLightBackground>
          <StyledHeadlineWrapper variant="strong_700">
            We are a proud member of:
            <StyledImageWrapper>
              <StyledLink to="https://swisspolishblockchain.org/">
                <StyledImage
                  src={swissPolishBlockchainImage}
                  alt="swissPolishBlockchainIcon"
                />
              </StyledLink>

              <StyledLink to="https://blockchain-polska.org/">
                <StyledImage
                  src={polishBlockchainImage}
                  alt="polishBlockchainIcon"
                />
              </StyledLink>
            </StyledImageWrapper>
          </StyledHeadlineWrapper>
        </Section>
      </ThemeProvider>
    </>
  );
};
