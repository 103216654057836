import { Stack, styled } from '@mui/material';

import { Section } from '@rumblefish/ui/Rumblefish23Theme';

export const WhoWeAreSection = styled(Section)(({ theme }) => ({
  contain: 'paint',
  borderBottom: `1px solid ${theme.palette.invertedColors?.PLUS_7}`,
  margin: theme.spacing(0, 0, 16, 0),
  [theme.breakpoints.down('md')]: {
    margin: theme.spacing(0, 0, 15, 0),
  },
  [theme.breakpoints.down('sm')]: {
    margin: theme.spacing(0, 0, 9, 0),
  },
}));

export const StyledStack = styled(Stack)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
