import { Stack, styled, Typography } from '@mui/material';

import { Link, Button } from '@rumblefish/ui/Rumblefish23Theme';

export const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(16, 0, 0, 0),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(0, 0, 10, 0),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0),
    gap: theme.spacing(4),
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

export const TextStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(8),
  flex: 1,
}));

export const BlogsStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  flex: 2,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: theme.spacing(4, 0, 6, 0),
    gap: theme.spacing(6),
  },
}));

export const ContentStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 0, 20, 0),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 0, 10, 0),
    flexDirection: 'column',
  },
}));

export const DesktopButton = styled(Button)(({ theme }) => ({
  '.MuiStack-root': {
    backgroundColor: 'transparent !important',
    border: '1px solid white',
    boxShadow: `0px 0px 6px 4px ${theme.palette.raspberryColors?.PLUS_2}, 0px 0px 6px 4px ${theme.palette.raspberryColors?.PLUS_2} inset !important`,
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const MobileButton = styled(Button)(({ theme }) => ({
  width: 'auto',
  '.MuiStack-root': {
    backgroundColor: 'transparent !important',
    border: '1px solid white',
    boxShadow: `0px 0px 6px 4px ${theme.palette.raspberryColors?.PLUS_2}, 0px 0px 6px 4px ${theme.palette.raspberryColors?.PLUS_2} inset !important`,
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const StyledHeadlineWrapper = styled(Typography)(({ theme }) => ({
  display: 'flex',
  textJustify: 'inter-word',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.invertedContent?.PRIMARY,
  WebkitTextFillColor: 'currentcolor',
  margin: theme.spacing(8, 0),

  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
  },
}));

export const StyledImageWrapper = styled('span')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.invertedContent?.PRIMARY,
  WebkitTextFillColor: 'currentcolor',
  padding: theme.spacing(5, 0),
  gap: theme.spacing(10),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(3, 0),
    gap: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 0),
    gap: theme.spacing(2),
    flexDirection: 'column',
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  maxHeight: '48px',

  [theme.breakpoints.down('md')]: {
    maxWidth: '250px',
    width: '45%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '200px',
  },
}));

export const StyledImage = styled('img')(({ theme }) => ({
  objectFit: 'contain',
  maxHeight: '48px',

  [theme.breakpoints.down('md')]: {
    maxWidth: '250px',
    width: '45%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '200px',
  },
}));
