import { Grid, Stack, Typography, styled } from '@mui/material';
import { Button, Section } from '@rumblefish/ui/Rumblefish23Theme';
import { fluidSize } from '@rumblefish/ui/src/theme/rumblefish23Theme/utils';

export const HeroSection = styled(Section)(() => ({
  position: 'relative',
}));

export const StyledGrid = styled(Grid)(() => ({
  padding: '0',
  margin: '0',
  listStyle: 'none',
  display: 'flex',
  WebkitFlexFlow: 'row',
  lineHeight: '30px',
}));

export const BubblesStyledStack = styled(Stack)(({ theme }) => ({
  width: '40%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  transform: 'none',
  [theme.breakpoints.down('xl')]: {
    width: '29%',
  },
  [theme.breakpoints.down('md')]: {
    transform: `translateX(${theme.utils.fluidSize({
      minSize: -40,
      maxSize: 550,
    })})`,
    width: 'auto',
    position: 'absolute',
    zIndex: -1,
    filter: 'blur(4.5px)',
  },
}));

export const TextStyledStack = styled(Stack)(({ theme }) => ({
  width: '60%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.down('xl')]: {
    width: '71%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const StyledRowGrid = styled(Grid)(({ theme }) => ({
  margin: '1px',
  minWidth: '65px',
  position: 'relative',
  flex: '1 0 auto',
  height: 'auto',
  display: 'flex',
  transform: 'translateX(-30%)',
  alignItems: 'center',
  [theme.breakpoints.down('xl')]: {
    transform: 'translateX(-45%)',
  },
  [theme.breakpoints.down('lg')]: {
    transform: 'translateX(-110%)',
    maxHeight: '120px',
    minWidth: '60px',
  },
  [theme.breakpoints.down('md')]: {
    width: '120px',
    height: '130px',
    transform: 'translateX(20%)',
  },
  '&:before': {
    content: "''",
    paddingTop: theme.utils.fluidSize({
      minSize: 100,
      maxSize: 145,
      maxBp: 1500,
    }),
    [theme.breakpoints.up('xl')]: {
      paddingTop: '145px',
    },
  },
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(9, 0),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(6, 0, 9, 0),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 0, 9, 0),
  },
}));

export const StyledIntroStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  marginBottom: theme.spacing(2),
}));

export const CompaniesStyledStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(7),
  marginTop: theme.spacing(2),
  textAlign: 'center',
}));

export const ButtonSectionStack = styled(Stack)(() => ({
  width: '100%',
  display: 'flex',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  '.MuiStack-root': {
    backgroundColor: 'transparent !important',
    border: '1px solid white',
    boxShadow: `0px 0px 6px 4px ${theme.palette.raspberryColors?.PLUS_2}, 0px 0px 6px 4px ${theme.palette.raspberryColors?.PLUS_2} inset !important`,
  },
}));

export const StyledTitle = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(2),

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const StyledHeroHeadingWrapper = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  width: '110%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',

  '& > ol': {
    position: 'relative',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    height: `${fluidSize({ minSize: 48, maxSize: 88 })}`,
    overflow: 'hidden',
  },

  '& > ol li': {
    height: `110%`,
    transform: `translateY(calc(-100% * var(--word-counter) - 5%))`,
  },

  '& > ol li span': {
    height: `100%`,
  },

  [theme.breakpoints.up('xl')]: {
    '& > ol': {
      height: '88px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& > ol': {
      height: '90px',
    },
    '& > ol li span': {
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
}));

export const StyledHeroHeading = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  display: 'flex',
  justifyContent: 'flex-start',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

export const StyledDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.TETRIARY,
}));

export const StyledSpan = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
  whiteSpace: 'nowrap',
}));
