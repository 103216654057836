import { Stack, styled, Typography } from '@mui/material';
import { motion } from 'framer-motion';

export const StyledStack = styled(Stack)(({ theme }) => ({
  zIndex: 1000,
  padding: theme.spacing(0, 0, 16, 0),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(0, 0, 9, 0),
  },
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(4),
  },
}));
export const TextStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(1),
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(3),
  },
}));
export const Title = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  [theme.breakpoints.down('md')]: {
    whiteSpace: 'nowrap',
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  color: theme.palette.greyBorder?.LIGHT,
}));
export const StyledBarsStack = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  margin: `0 calc((-1) * ((100vw - 100%) / 2))`,
  ['>div']: {
    '&:first-of-type': {
      borderTopColor: theme.palette.invertedBorder?.LIGHT,
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
    },
    borderBottomColor: theme.palette.invertedBorder?.LIGHT,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
  },
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(2),
  },
}));
export const StyledSingleBarStack = styled(motion.div)(() => ({
  cursor: 'pointer',
  width: '100%',
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
}));
export const StyledSingleInnerBarStack = styled(Stack)(({ theme }) => ({
  maxWidth: `${theme.breakpoints.values.xl}px`,
  width: '90%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(8, 0),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: theme.spacing(5, 6),
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    padding: theme.spacing(5, 3),
  },
}));
export const StyledBarTitleStack = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));
export const BarMaskTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.greyContent?.PRIMARY,
}));
export const BarTitle = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.greyBackgroundColor?.SURFACE} !important`,
}));
export const BarMaskDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.greyContent?.PRIMARY,
}));
export const StyledMaskContainer = styled(motion.div)(({ theme }) => ({
  width: '100%',
  background: theme.palette.invertedColors?.MINUS_4,
  position: 'absolute',
  top: 0,
  bottom: 0,
  margin: 'auto 0',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledArrowIcon = styled(motion.svg)(({ theme }) => ({
  height: theme.utils.fluidSize({ minSize: 32, maxSize: 64 }),
  maxHeight: '64px',
  minHeight: '32px',
  aspectRatio: '1/1',

  ['&.ArrowIcon-static']: {
    color: theme.palette.greyBorder?.LIGHT,
    zIndex: 1,
  },
  ['&.ArrowIcon-hover']: {
    color: theme.palette.greyContent?.PRIMARY,
    zIndex: 2,
  },
}));
