import type { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import type { StackProps } from '@mui/material';
import {
  CustomizedAccordion,
  Section,
  NameBanner,
} from '@rumblefish/ui/Rumblefish23Theme';
import {
  StyledStack,
  StyledContentStack,
  StyledTextStack,
  DescriptionText,
  MainText,
  StyledAccordionStack,
} from './styles';
import { AccordionData, whatWeDoData } from './whatWeDoData';

export const WhatWeDo = ({ ...props }: StackProps): ReactJSXElement => {
  return (
    <Section width="normal">
      <StyledStack {...props}>
        <NameBanner type="section" text={'What do we do?'} />
        <StyledContentStack>
          <StyledTextStack>
            <MainText variant="display_sm">{whatWeDoData.mainText}</MainText>
            <DescriptionText variant="strong_400">
              {whatWeDoData.description}
            </DescriptionText>
          </StyledTextStack>
          <StyledAccordionStack>
            <CustomizedAccordion data={AccordionData()} />
          </StyledAccordionStack>
        </StyledContentStack>
      </StyledStack>
    </Section>
  );
};
