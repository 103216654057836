import { NumberStack, TextFill } from '@rumblefish/ui/Rumblefish23Theme';
import { StyledStack, WhoWeAreSection } from './styles';
import { whoWeAreData } from './whoWeAre.data';
import type { WhoWeAreProps } from './WhoWeAre.types';

export const WhoWeAre = ({ ...props }: WhoWeAreProps) => {
  return (
    <WhoWeAreSection width="normal">
      <StyledStack {...props}>
        <TextFill nameBannerText="Who we are?">
          {whoWeAreData.textFillText}
        </TextFill>

        <NumberStack counters={whoWeAreData.counters} />
      </StyledStack>
    </WhoWeAreSection>
  );
};
