import { Stack, Typography, styled } from '@mui/material';

export const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(0),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 0, 5, 0),
    gap: theme.spacing(4),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0, 0, 9, 0),
    gap: theme.spacing(4),
  },
}));

export const StyledContentStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(10),

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
}));

export const StyledTextStack = styled(Stack)(({ theme }) => ({
  flex: 0.85,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  [theme.breakpoints.down('xl')]: {
    flex: 0.95,
  },
  [theme.breakpoints.down('lg')]: {
    flex: 1,
  },
}));

export const StyledAccordionStack = styled(Stack)(({ theme }) => ({
  flex: 1,
  minHeight: '1270px',
  [theme.breakpoints.down('sm')]: {
    minHeight: 'auto',
  },
}));

export const MainText = styled(Typography)(({ theme }) => ({
  ...theme.mixins.neutralGradient,
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));

export const DescriptionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.invertedContent?.PRIMARY,
}));
