import { Contact, Layout } from '@rumblefish/ui/Rumblefish23Theme';

import { NextHeadWithMeta } from '../NextHeadWithMeta';
import { Hero } from './Hero';
import { homeSeo } from './Homepage.seo';
import { HomePageRumbleFishBlog } from './HomePageRumbleFishBlog';
import { JoinThem } from './JoinThem';
import { LatestCaseStudy } from './LatestCaseStudy';
import { Testimonials } from './Testimonials';
import type { HomepageProps } from './types';
import { WhatWeDo } from './WhatWeDo';
import { WhoWeAre } from './WhoWeAre';

export const Homepage = ({
  blogPosts,
  testimonials,
  caseStudies,
}: HomepageProps) => {
  const latestCaseStudy = caseStudies[0];

  return (
    <Layout blogPosts={blogPosts}>
      <NextHeadWithMeta {...homeSeo} />
      <Hero />
      <WhoWeAre />
      <WhatWeDo />
      <Testimonials testimonialsData={testimonials} />
      <LatestCaseStudy latestCaseStudy={latestCaseStudy} />
      <JoinThem />
      <HomePageRumbleFishBlog blogPosts={blogPosts} />
      <Contact color="raspberry" />
    </Layout>
  );
};
