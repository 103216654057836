export const whoWeAreData = {
  textFillText:
    "Hi there! We're Rumble Fish - a team of world-class experts in bespoke software development. Our engineers are highly skilled in blockchain, cloud solutions, and defi/fintech development. Our strength and pride is the ability to take ownership of the entire development process and be a true partner and advisor for our customers. Our mission is to craft state-of-the-art digital products using battle-tested technologies. Try us!",
  counters: [
    {
      caption: 'uniquely skilled devs',
      number: 40,
    },
    {
      caption: 'pet-friendly office',
      number: 1,
    },
    {
      caption: 'years in business',
      number: 7,
    },
    {
      caption: 'projects',
      number: 31,
    },

    {
      caption: 'passion for coding',
      number: 999,
      infinite: true,
    },
  ],
};
