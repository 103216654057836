import { Typography, useTheme } from '@mui/material';
import {
  AIIcon,
  Bubble,
  AwsIcon,
  NftIcon,
  WebIcon,
  DefiIcon,
  TeamIcon,
  DesignIcon,
  MobileIcon,
  FintechIcon,
  StartupIcon,
  BlockchainIcon,
  CompaniesCarousel,
} from '@rumblefish/ui/Rumblefish23Theme';
import type { BubbleParams } from '@rumblefish/ui/src/components/Rumblefish23Theme/Bubble/Bubble.types';
import gsap from 'gsap';
import { useEffect, useMemo, useRef } from 'react';

import type { HeroProps } from './Hero.types';
import {
  StyledGrid,
  StyledSpan,
  HeroSection,
  StyledStack,
  StyledTitle,
  StyledButton,
  StyledRowGrid,
  TextStyledStack,
  StyledIntroStack,
  StyledDescription,
  StyledHeroHeading,
  BubblesStyledStack,
  ButtonSectionStack,
  CompaniesStyledStack,
  StyledHeroHeadingWrapper,
} from './styles';

export const Hero = ({ ...props }: HeroProps) => {
  const theme = useTheme();
  const olRef = useRef<HTMLOListElement>(null);

  const services = [
    'Software',
    'Blockchain',
    'Web3 product',
    'Smart Contracts',
    'Mobile app',
    'Web platform',
    'AWS Cloud',
    'NFT marketplace',
    'DeFi',
    'Fintech',
    'AI product',
    'dApp',
    'Crypto wallet',
  ];

  const iconsArr: (BubbleParams | null)[][] = useMemo(() => {
    return [
      [
        {
          name: 'heroWebIcon',
          icon: WebIcon,
          top: 85,
          left: -200,
          size: 70,
          boxShadow: theme.mixins.raspberryGradientTransparent?.boxShadow || '',
          floatingY: 6.8,
          duration: 2.7,
        },
        null,
        {
          name: 'heroAwsIcon',
          icon: AwsIcon,
          top: 0,
          left: -50,
          size: 70,
          boxShadow: theme.mixins.redGradientTransparent?.boxShadow || '',
          floatingY: 7,
          duration: 2.3,
        },
        {
          name: 'heroTeamIcon',
          icon: TeamIcon,
          top: -50,
          left: 20,
          size: 75,
          boxShadow: theme.mixins.tealGradientTransparent?.boxShadow,
          floatingY: -3.6,
          duration: 2.6,
        },

        null,
      ],
      [
        {
          name: 'heroStartupIcon',
          icon: StartupIcon,
          top: 100,
          left: -100,
          size: 70,
          boxShadow: theme.mixins.greenGradientTransparent?.boxShadow || '',
          floatingY: 5,
          duration: 3.1,
        },
        {
          name: 'heroFintech',
          icon: FintechIcon,
          top: -60,
          left: -145,
          size: 115,
          boxShadow: theme.mixins.blueGradientTransparent?.boxShadow || '',
          floatingY: -8,
          duration: 3,
        },

        null,
        {
          name: 'heroNftIcon',
          icon: NftIcon,
          top: -40,
          left: -50,
          size: 80,
          boxShadow: theme.mixins.violetGradientTransparent?.boxShadow || '',
          floatingY: -4.7,
          duration: 2.5,
        },
        {
          name: 'heroDefiIcon',
          icon: DefiIcon,
          top: -50,
          left: 50,
          boxShadow: theme.mixins.grapeGradientTransparent?.boxShadow || '',
          floatingY: -6.8,
          size: 90,
          duration: 3.5,
        },
      ],
      [
        null,
        null,
        {
          name: 'heroDesignIcon',
          icon: DesignIcon,
          top: -80,
          left: -110,
          size: 95,
          boxShadow:
            theme.mixins.aquamarineGradientTransparent?.boxShadow || '',
          floatingY: -2.9,
          duration: 2.9,
        },

        null,
        {
          name: 'heroMobileIcon',
          icon: MobileIcon,
          top: -60,
          left: -50,
          size: 80,
          boxShadow: theme.mixins.magentaGradientTransparent?.boxShadow || '',
          floatingY: 7.4,
          duration: 4,
        },
      ],
      [
        {
          name: 'heroBlockchainIcon',
          icon: AIIcon,
          top: -90,
          left: 50,
          size: 75,
          boxShadow: theme.mixins.purpleGradientTransparent?.boxShadow || '',
          floatingY: 5.6,
          duration: 2.4,
        },
        null,

        null,
        {
          name: 'heroBlockchainIcon',
          icon: BlockchainIcon,
          top: -70,
          left: -50,
          size: 85,
          boxShadow: theme.mixins.cyanGradientTransparent?.boxShadow || '',
          floatingY: 7.6,
          duration: 3.4,
        },
        null,
      ],
    ];
  }, [theme.mixins]);

  useEffect(() => {
    const ctx = gsap.context(() => {
      if (olRef !== null && olRef.current !== null) {
        const spanTimeline = gsap.timeline({
          repeat: -1,
          repeatDelay: 1,
        });

        const serviceItems = olRef.current.childNodes;
        serviceItems.forEach((word, index) => {
          spanTimeline.fromTo(
            'ol',
            {
              '--word-counter': index - 1,
            },
            {
              '--word-counter': index,
              duration: 0.8,
              ease: 'elastic.out(0.4, 3)',
              delay: index === 0 ? 0 : 0.8,
            }
          );
        });
      }
    });
    return () => ctx.revert();
  }, [olRef]);

  return (
    <HeroSection width="normal" borderBottomOn>
      <StyledStack {...props}>
        <StyledIntroStack>
          <TextStyledStack>
            <StyledTitle variant="display_md">
              <StyledHeroHeadingWrapper variant="display_md">
                <ol ref={olRef}>
                  {services.map((service, index) => {
                    return (
                      <li key={index} className="service-item">
                        <StyledHeroHeading variant="display_md">
                          {service}
                        </StyledHeroHeading>
                      </li>
                    );
                  })}
                </ol>
              </StyledHeroHeadingWrapper>
              development tailored to your needs!
            </StyledTitle>
            <StyledDescription paragraph variant="strong_500">
              Rumble Fish helps entrepreneurs build and launch{' '}
              <StyledSpan variant="strong_500">
                bespoke digital products.
              </StyledSpan>
              <br /> We take care of the technology, so you can focus on your
              business
            </StyledDescription>

            <ButtonSectionStack>
              <StyledButton link="/contact" variant="primary">
                Contact us!
              </StyledButton>
            </ButtonSectionStack>
          </TextStyledStack>
          <BubblesStyledStack>
            {iconsArr.map((row, index) => {
              return (
                <StyledGrid key={index} container>
                  {row.map((el, rowIndex) => {
                    return (
                      <StyledRowGrid key={rowIndex} item>
                        {el && <Bubble params={el} />}
                      </StyledRowGrid>
                    );
                  })}
                </StyledGrid>
              );
            })}
          </BubblesStyledStack>
        </StyledIntroStack>

        <CompaniesStyledStack>
          <Typography
            variant="strong_400"
            sx={{ color: theme.palette.invertedContent?.PRIMARY }}>
            Join the ecosystem
            <br /> of our satisfied customers:
          </Typography>
          <CompaniesCarousel />
        </CompaniesStyledStack>
      </StyledStack>
    </HeroSection>
  );
};
